function addEmail() {
    return {
        formData: {
            email: ""
        },
        loading: false,
        isError: false,
        submitted: false,
        mtext:"Join us and be among the first to test Beet",
        submit() {
            this.loading = true;
            fetch("https://us-central1-artistsmatterinc.cloudfunctions.net/addemail", {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(this.formData)
            })
            .then(() => {
                console.log("form submitted")
            })
            .catch(() => {
                console.log("error")
                this.isError = true;
            })
            .finally(() => {
                this.formData.email = "";
                this.submitted = true;
                this.loading = false;
                this.mtext = "Thank you! We’ll be in touch soon!";
            })
        }
    }
  }